<template>
    <v-container fluid>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/veiculos/modelo" />
        </v-col>
      </v-row>
  
      <modelo-veiculo-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
    </v-container>
  </template>
  
  <script> 
    import modelos from '@/api/modelos'
    export default {
      name: 'AdicionarModeloVeiculo',
  
      components: {
      ModeloVeiculoCampos: () => import('./ModeloVeiculoCampos'),
    },
  
      data () {
        return {
          carregando: false,
          validacaoFormulario: {},
        }
      },
  
      methods: {
        async salvar (modelo) {
          this.carregando = true
          this.validacaoFormulario = {}
  
          try { 
            console.log(modelo)
            let dados = {
                descricao: modelo.descricao,
                veiculo_marca_id: modelo.veiculo_marca
            }
            await modelos.inserir(dados)

            this.$router.push('/veiculos/modelo', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_adicionar') }))
          } catch (e) {
            if (e.response.status === 422) {
              this.validacaoFormulario = this.$erroApi.validacao(e)
              return
            }
  
            this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
          } finally {
            this.carregando = false
          }
        },
      },
    }
  </script>
  