import axios from '@/plugins/axios'

export default {

  async listar (dados) {
    const parametros = new URLSearchParams(dados).toString()
    return await axios.get(`modelos-veiculo?${parametros}`)
  },
  async buscar (dados) { 
    return await axios.get(`modelos-veiculo/${dados}`)
  },
  async inserir (dados) {
    return await axios.post('modelos-veiculo', dados)
  },
  async atualizar (id, dados) {
    return await axios.put(`modelos-veiculo/${id}`, dados)
  },
}
